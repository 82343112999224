import React from "react";
import {NavProps} from "./NavProps";
import {formatPhoneNumber, trackContactClicked} from "@renaissancerentals/renaissance-component";

export const FooterNav: React.FC<NavProps> = (
    {contact, setShowApplicationModal, setShowContactModal, handleRefToFloorPlan, handleRefToMap, isMainPage}) => {
    return (
        <ul>
            <li><a href="#apply" onClick={() => setShowApplicationModal(true)}>apply</a></li>
            <li><a href="#contact" onClick={() => {
                setShowContactModal(true);
                trackContactClicked("covenanter-hill");
            }}>contact</a></li>
            <li><a href={isMainPage ? "#floorplan" : "/#floorplan"} onClick={handleRefToFloorPlan}>floorplans</a></li>
            <li><a href={handleRefToMap ? "#location" : "/#location"} onClick={handleRefToMap}>location</a></li>
            <li><a href={"tel:" + contact.phone}>{formatPhoneNumber(contact.phone)}</a></li>
        </ul>
    );
}
