import React, {useRef, useState} from "react";
import {
    Contact,
    DriveGallery,
    FeatureSection,
    FloorplanCardData,
    FloorplanSectionSkeleton,
    FloorplansSection,
    PropertyDetails,
    sortFloorplans,
    TeamSection, trackContactClicked
} from "@renaissancerentals/renaissance-component";
import {HeroSection} from "../components/hero/HeroSection";
import {ActionSection} from "../components/action/ActionSection";
import {AmenitiesSection} from "../components/amenities/AmenitiesSection";
import {PropertyLocation} from "@renaissancerentals/renaissance-component/lib/property/PropertyLocation";
import {MainLayout} from "../layout/MainLayout";
import {propertyId} from "../services/DataService";

export const Home: React.FC<PageProps> = ({property, isLoading, contact}) => {

    const [showContactModal, setShowContactModal] = useState(false);
    const [showApplicationModal, setShowApplicationModal] = useState(false);
    const floorPlanRef = useRef<HTMLDivElement>(null);
    const mapRef = useRef<HTMLDivElement>(null);

    const handleRefToFloorPlan = () => {
        floorPlanRef.current?.scrollIntoView({
            behavior: "smooth",
            block: "start"
        });
    };
    const handleRefToMap = () => {
        mapRef.current?.scrollIntoView({
            behavior: "smooth",
            block: "start"
        });
    };

    return (
        <MainLayout handleRefToFloorPlan={handleRefToFloorPlan}
                    handleRefToMap={handleRefToMap} isMainPage={true}
                    showApplicationModal={showApplicationModal} showContactModal={showContactModal}
                    setShowApplicationModal={setShowApplicationModal}
                    setShowContactModal={setShowContactModal}
                    contact={contact} property={property}
        >
            <HeroSection/>
            <FeatureSection>
                <p className="container">Covenanter Hill is a relaxed walkable neighborhood located on Bloomington’s
                    lively east side,
                    with
                    original architecture rooted in tradition. Its unique character, highlighted by lush green
                    spaces
                    and vibrant colors, is a refreshing change of pace.</p>
            </FeatureSection>
            <ActionSection handleApplyClicked={() => setShowApplicationModal(true)}
                           handleContactClicked={() => {
                               setShowContactModal(true);
                               trackContactClicked("covenanter-hill");
                           }}
                           handleRefToFloorPlan={handleRefToFloorPlan}
                           isLoading={isLoading}
                           floorplans={property.floorplans}/>
            <AmenitiesSection/>
            <h2 className="heading"><span className="emphasized">Images</span></h2>
            <div className="container">
                <DriveGallery
                    propertyId={propertyId}
                    driveId={property.photosFolderId}
                    initialSize={9}
                    type="simple"
                    showName={false}
                />
            </div>
            <div ref={floorPlanRef} id="floorplan" className="reference">
            </div>
            {isLoading ?
                <FloorplanSectionSkeleton/> :
                <>{property?.floorplans ? <FloorplansSection
                    propertyId={propertyId}
                    floorplans={
                        sortFloorplans(
                            property.floorplans.filter(floorplan => floorplan.active).map(floorplan => floorplan as FloorplanCardData
                            ), "featured")
                    }/> : ""}

                </>
            }

            <div ref={mapRef} id="location" className="reference">
            </div>
            <h2 className="heading"><span className="emphasized">Location</span></h2>
            <PropertyLocation property={property} isLoading={isLoading} handleRefToContact={() => {
                setShowContactModal(true);
                trackContactClicked("covenanter-hill");
            }}/>

            <TeamSection isLoading={isLoading} teamMembers={property.teamMembers} propertyId={propertyId}/>


        </MainLayout>
    );
};

export interface PageProps {
    isLoading: boolean;
    property: PropertyDetails;
    contact: Contact;
}
